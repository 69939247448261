import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/courses/courseSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/advantages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/assessments.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/banner/banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/big-learning.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/for-queries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/key-offerings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/our-learners-speak.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/sap-training.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/tools-for-teachers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/dev.exc.academy/components/landing/who-we-are.tsx");
