"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { toast } from "@/components/ui/use-toast"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import axios from "axios";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input"
import { useEffect, useState } from "react"
import { Icons } from "../ui/icons"
import ApiFromClient from '@/lib/getFromClient';
import useDebounce from "@utils/useDebounce"; 
import { Span } from "next/dist/trace"

const countries = [
    {
      id: 1,
      country: "India",
      state: [
        { id: 1, state: "Andhra Pradesh" },
        { id: 2, state: "Arunachal Pradesh" },
        { id: 3, state: "Assam" },
        { id: 4, state: "Bihar" },
        { id: 5, state: "Chhattisgarh" },
        { id: 6, state: "Goa" },
        { id: 7, state: "Gujarat" },
        { id: 8, state: "Haryana" },
        { id: 9, state: "Himachal Pradesh" },
        { id: 10, state: "Jharkhand" },
        { id: 11, state: "Karnataka" },
        { id: 12, state: "Kerala" },
        { id: 13, state: "Madhya Pradesh" },
        { id: 14, state: "Maharashtra" },
        { id: 15, state: "Manipur" },
        { id: 16, state: "Meghalaya" },
        { id: 17, state: "Mizoram" },
        { id: 18, state: "Nagaland" },
        { id: 19, state: "Odisha" },
        { id: 20, state: "Punjab" },
        { id: 21, state: "Rajasthan" },
        { id: 22, state: "Sikkim" },
        { id: 23, state: "Tamil Nadu" },
        { id: 24, state: "Telangana" },
        { id: 25, state: "Tripura" },
        { id: 26, state: "Uttar Pradesh" },
        { id: 27, state: "Uttarakhand" },
        { id: 28, state: "West Bengal" }
      ]
    },
    {
      id: 2,
      country: "United Arab Emirates",
      state: [
        { id: 1, state: "Abu Dhabi" },
        { id: 2, state: "Dubai" },
        { id: 3, state: "Sharjah" },
        { id: 4, state: "Ajman" },
        { id: 5, state: "Fujairah" },
        { id: 6, state: "Ras Al Khaimah" },
        { id: 7, state: "Umm Al Quwain" }
      ]
    },
    {
      id: 3,
      country: "other",
    }
  ];
  

export function ModalForm() {
    const [countryList, setCountryList] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [cities, setCities] = useState<any>([]);
    const [checkEmail, setCheckEmail] = useState<any>();
    const [checkEmailValidation, setCheckEmailValidation] = useState<any>(true);

    const formSchema = z.object({
        username: z
            .string({ required_error: 'Name is required' })
            .min(3, { message: 'Name must be at least 3 characters' })
            .max(30, { message: 'Name cannot contain more than 30 characters' })
            .refine(
                (value) => /^[A-Za-z][A-Za-z\s]*$/.test(value),
                { message: 'Only alphabets allowed' }
            ),
        email: z.string({ required_error: "Email is required" }).min(1, { message: "Email is required" })
            .regex(/^[A-Za-z0-9@.]+$/, { message: "Invalid email" })
            .email("Invalid email"),
        phone: z.string({ required_error: "Phone number required!" })
            .min(12, { message: 'Phone Number should contain 10 digit' })
            .max(16, { message: 'Phone Number should contain 10 digit' }).refine((value) => {
                const zeroCount = (value.match(/0/g) || []).length;
                return zeroCount <= 7;
            }, {
                message: 'Invalid Phone number.',
            }).refine((value) => {
                // Check if any digit repeats 10 times
                const repeatingDigit = /(\d)\1{9}/;
                return !repeatingDigit.test(value);
            }, {
                message: 'Invalid Phone number.',
            }),
        state: z.string({ required_error: "Please select option", }).optional(),
        are_you: z.string({ required_error: "Please select option", }),
        course_preference: z.string({ required_error: "Please select option", }),
        other: z.string().optional(),
        country: z.string().optional(),
        otherState: z.string().optional()
    });

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(formSchema),
    });

    const emailValidate = useDebounce((value: string) => {
        setCheckEmail(value);
    }, 2000);

    useEffect(() => {
        fetchCountryList();
        const modalShownTimestamp = localStorage.getItem('modalShownTimestamp');
        if (!modalShownTimestamp) {
            setIsOpen(true);
            // localStorage.setItem('modalShownTimestamp', Date.now().toString());
        }
    }, []);

    // useEffect(() => {
    //     fetchCountryList();
    //     const modalShown = sessionStorage.getItem('modalShown');
    //     if (!modalShown) {
    //         setIsOpen(true);
    //         sessionStorage.setItem('modalShown', 'true');
    //     }
    // }, [])

    const fetchCountryList = async () => {

        setCountryList(countries)
        // try {
        //     const response = await axios.get("https://countriesnow.space/api/v0.1/countries");
        //     console.log("first",response)
        //     setCountryList(response?.data?.data);
        // } catch (error) {
        //     console.log("Error while fetching", error);
        // }
    };

    useEffect(() => {
        if (!checkEmailValidation) {
            const timeoutId = setTimeout(() => {
                setIsOpen(false);
                localStorage.setItem('modalShownTimestamp','true')
            }, 4000); // Close the dialog after 2 seconds
            return () => clearTimeout(timeoutId); // Clean up the timeout if the component unmounts or dependencies change
        }
    }, [checkEmailValidation]);

    const inputValidation = (e: any) => {
        const key = e.key;
        const isAlphabetOrSpace = /^[a-zA-Z ]$/.test(key); // Include space in the regex
        if (!isAlphabetOrSpace && !['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
            e.preventDefault();
        }
    };

    const onSubmit = async (data: any) => {
        // console.log("submit data",data)
        setLoading(true)
        let datas = new FormData();
        data?.course_preference === "other" 
        ? datas.append("course_preference", data?.other)
        : datas.append("course_preference", data?.course_preference);
    
        data?.country === "other"
        ? datas.append("state", data?.otherState)
        : datas.append("state", data?.state);

        datas.append("name", data?.username);
        datas.append("email", data?.email);
        datas.append("phone", data?.phone);
        datas.append("are_you", data?.are_you);

        try {
            const response = await ApiFromClient.post(`/leads/addCallBack`, datas, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response?.status === 201 || response?.status === 200) {
                toast({
                    title: "Success",
                    description: "Successfully submitted",
                    variant: 'success'
                });
                setLoading(false)
                setIsOpen(false);
                localStorage.setItem('modalShownTimestamp','true')
            } else {
                toast({
                    title: "Error",
                    description: "Something went wrong, please try again",
                    variant: 'destructive'
                });
                setLoading(false)
            }
        } catch (error) {
            console.error('Error uploading Excel file:', error);
            setLoading(false)
        }
    };

    const handleChange = (event: any) => {
        const selectedCountry = countryList?.find((country: any) => country?.country === event);
        setCities(selectedCountry?.state || []);
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        emailValidate(value);
    };

    useEffect(() => {
        async function getAllLeadsLists() {
            try {
                let data = new FormData();
                data.append("email", checkEmail);
                const response = await ApiFromClient.post(`/leads/validateCallBackEmail`, data);
                setCheckEmailValidation(response?.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        getAllLeadsLists();
    }, [checkEmail]);

  return (
    <>
    <Dialog open={isOpen} >
    <DialogContent className="max-w-[600px] rounded-md remove-close-btn">
        <DialogHeader className="flex flex-col space-y-1.5 sm:text-left z-50 shadow-sm text-start md:px-8 px-4 py-5 bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/our-courses/stepper_bg.webp')] relative">
            <DialogTitle>Fill the given details to Continue.</DialogTitle>
        </DialogHeader>
        <div className="sm:px-6 px-3 py-3 max-h-[cals(100vh-100px)] overflow-auto">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Name *</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" className="rounded-none h-11" {...field} onChange={field.onChange} maxLength={30} onKeyDown={inputValidation} />
                                        </FormControl>
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="sm:col-span-1 col-span-2">
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Email *</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Email" className="rounded-none h-11" {...field} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
                                                field.onChange(e);
                                                handleChangeInput(e)

                                            }} type="email" onKeyDown={(e: any) => {
                                                if (e.keyCode === 32) {
                                                    e.preventDefault();
                                                }
                                            }} />
                                        </FormControl>
                                        {!checkEmailValidation && <span className="font-medium text-destructive absolute text-[12px]">User already exist</span>}
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="sm:col-span-1 col-span-2">
                            <FormField
                                control={form.control}
                                name="phone"
                                render={({ field }: any) => (
                                    <FormItem className="grid grid-cols-1 items-center gap-6">
                                        <div className="">
                                            <FormLabel>Mobile Number *</FormLabel>
                                            <FormControl>
                                                <PhoneInput
                                                    placeholder="Enter phone number *"
                                                    defaultValue={field.value}
                                                    // onClick={setValue}
                                                    defaultCountry="IN"
                                                    international
                                                    limitMaxLength={true}
                                                    // maxLength="16"
                                                    countryCallingCodeEditable={false}
                                                    className="border-[#D2D6DE] rounded-none h-11 border-solid border ps-2 text-[#828282]"
                                                    {...field}
                                                    onChange={field.onChange}
                                                    rules={{ required: true }} />
                                            </FormControl>
                                            <FormMessage className="absolute text-[12px]" />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-2">
                            <FormField
                                control={form.control}
                                name="country"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Country</FormLabel>
                                        <FormControl>
                                            <div>
                                                <Select onValueChange={(e:any)=>{
                                                    field.onChange(e)
                                                    handleChange(e)
                                                }} defaultValue={field.value}>
                                                    <SelectTrigger style={{ marginBottom: "0" }} className="h-11 bg-white focus:ring-0 focus-visible:ring-offset-0 focus-visible:ring-0 rounded-none border border-[#D0D0D0] text-sm text-[#000] mb-8">
                                                        <SelectValue placeholder="Select Your Country" className="text-sm" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {countryList.map((country: any) => (
                                                            <SelectItem key={country?.id} value={country?.country}>
                                                                {country?.country}
                                                            </SelectItem>
                                                         ))}
                                                        {/* <SelectItem value="karnataka">karnataka</SelectItem>
                                                        <SelectItem value="tamil nadu">Tamil Nadu</SelectItem>
                                                        <SelectItem value="kerala">kerala</SelectItem> */}
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </FormControl>
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        {cities.length > 0 && 
                        <div className="col-span-2">
                        <FormField
                            control={form.control}
                            name="state"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>State *</FormLabel>
                                    <FormControl>
                                        <div>
                                            <Select onValueChange={(e:any)=>{
                                                field.onChange(e)
                                                // handleChange(e)
                                            }} defaultValue={field.value}>
                                                <SelectTrigger style={{ marginBottom: "0" }} className="h-11 bg-white focus:ring-0 focus-visible:ring-offset-0 focus-visible:ring-0 rounded-none border border-[#D0D0D0] text-sm text-[#000] mb-8">
                                                    <SelectValue placeholder="Select Your State" className="text-sm" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {cities.map((city: any) => (
                                                        <SelectItem key={city?.id} value={city?.state}>
                                                            {city?.state}
                                                        </SelectItem>
                                                     ))}
                                                    {/* <SelectItem value="karnataka">karnataka</SelectItem>
                                                    <SelectItem value="tamil nadu">Tamil Nadu</SelectItem>
                                                    <SelectItem value="kerala">kerala</SelectItem> */}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </FormControl>
                                    <FormMessage className="absolute text-[12px]" />
                                </FormItem>
                            )}
                        />
                    </div>}
                    {form.watch("country") === "other" &&
                        <div className="col-span-2">
                            <FormField
                                control={form.control}
                                name="otherState"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input placeholder="Enter a state" className="rounded-none h-11" {...field} onChange={field.onChange} />
                                        </FormControl>
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>}
                        <div className="sm:col-span-1 col-span-2">
                            <FormField
                                control={form.control}
                                name="are_you"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Are you ? *</FormLabel>
                                        <FormControl>
                                            <div>
                                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                    <SelectTrigger style={{ marginBottom: "0" }} className="h-11 bg-white focus:ring-0 focus-visible:ring-offset-0 focus-visible:ring-0 rounded-none border border-[#D0D0D0] text-sm text-[#000] mb-8">
                                                        <SelectValue placeholder="Select your occupation" className="text-sm" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="Working Professional">Working Professional</SelectItem>
                                                        <SelectItem value="Student-graduate">Student Graduate</SelectItem>
                                                        <SelectItem value="Student-PostGraduate">Student PostGraduate</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </FormControl>
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="sm:col-span-1 col-span-2">
                            <FormField
                                control={form.control}
                                name="course_preference"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Course Preference *</FormLabel>
                                        <FormControl>
                                            <div>
                                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                    <SelectTrigger style={{ marginBottom: "0" }} className="h-11 bg-white focus:ring-0 focus-visible:ring-offset-0 focus-visible:ring-0 rounded-none border border-[#D0D0D0] text-sm text-[#000] mb-8">
                                                        <SelectValue placeholder="Select your course" className="text-sm" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="SAP Ariba">SAP Ariba</SelectItem>
                                                        <SelectItem value="SAP SF EC">SAP SF EC</SelectItem>
                                                        <SelectItem value="SAP SF RCM & ONB">SAP SF RCM & ONB</SelectItem>
                                                        <SelectItem value="SAP HCM Payroll">SAP HCM Payroll</SelectItem>
                                                        <SelectItem value="SAP EAM">SAP EAM</SelectItem>
                                                        <SelectItem value="SAP MM">SAP MM</SelectItem>
                                                        <SelectItem value="other">Other</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </FormControl>
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        {form.watch("course_preference") === "other" &&
                        <div className="col-span-2">
                            <FormField
                                control={form.control}
                                name="other"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input placeholder="Enter a course preference" className="rounded-none h-11" {...field} onChange={field.onChange} />
                                        </FormControl>
                                        <FormMessage className="absolute text-[12px]" />
                                    </FormItem>
                                )}
                            />
                        </div>}
                    </div>
                    <div className="flex justify-end pt-8">
                    <Button className="h-11 rounded-none text-lg w-[100%] bg-[#302A33] uppercase font-normal" disabled={loading}>
                    {loading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}Submit</Button>
                    </div>
                </form>
            </Form>
        </div>
    </DialogContent>
    </Dialog>
    </>
  )
}
