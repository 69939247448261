import Link from "../ui/link";

const ReadMore = (props: Course) => {

    return (
        // <Link href="/course-details/sap-ariba-certification-training" variant="primary" className="uppercase px-5 py-2 w-full text-center font-semibold text-base lg:inline-block md:inline-block block">Read More</Link>
        // <Link href={`/course-details/${props.title.replace(/\s/g, "-").toLocaleLowerCase()}/${props.cid}`} variant="primary" className="uppercase px-5 py-2 w-full text-center font-semibold text-base lg:inline-block md:inline-block block">Read More</Link>
        <Link href={`/course-details/${props.slug}`} variant="primary" className="uppercase px-5 py-4 w-full text-center font-semibold text-base lg:inline-block md:inline-block block leading-normal">EXPLORE MORE</Link>
        // <Link href={`/course-details/${props.slug}`} className="block text-center uppercase px-5 py-2 w-full font-semibold text-base lg:inline-block md:inline-block block">
        //     <a className="block text-center uppercase px-5 py-2 w-full font-semibold text-base lg:inline-block md:inline-block block">
        //         EXPLORE MORE
        //     </a>
        // </Link>
    );
}

export default ReadMore;