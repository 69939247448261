import React from "react";

interface LinkProps {
  href: string;
  target?: string;
  children: React.ReactNode;
  variant?: any;
  className?: string;
  icon?: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ href, target, children, variant = 'default', className = '', icon }) => {
  const getClassName = () => {
    switch (variant) {
      case 'primary':
        return 'hover:bg-gray-800 bg-black font-semibold text-white font-xl';
      case 'secondary':
        return '';
      case 'viewall':
        return 'hover:bg-[#121212] hover:text-white text-[#121212] font-semibold text-sm lg:text-lg md:text-base px-7 py-4 border-solid border-2 border-[#121212]';
      case 'text':
        return 'hover:text-[#000000] text-[#121212] font-semibold text-sm lg:text-lg md:text-base';
      default:
        return 'px-7 py-4 text-gray font-semibold border-solid border-2 border-[#121212] bg-white text-sm lg:text-lg md:text-base hover:bg-gray-100';
      case 'add-button':
        return 'border border-[#121212] bg-transparent text-[#121212] hover:bg-[#121212] hover:text-[#ffffff] font-semibold border-1 text-sm px-3 py-2 rounded';
      case 'register':
        return 'hover:bg-gray-800 bg-black text-white text-base font-[600] px-7 py-1 lg:flex md:flex block text-center';
    }
  };

  const combinedClassName = `${getClassName()} ${className}`.trim();

  return (
    <a href={href} target={target} rel="preload" className={combinedClassName}>
      {children}
      {icon && <span className="ms-1">{icon}</span>}
    </a>
  );
};

export default Link;
