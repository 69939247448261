import Link from "../ui/link";

const ExploreCourses = ({className = '' }) => {
    const getClassName = () => {
        
      };

    const combinedClassName = `${getClassName()} ${className}`.trim();
    return (
        <Link href="/our-courses" variant="primary" className="uppercase py-4 lg:px-11 md:px-11 px-7 text-center lg:inline-block md:inline-block block">Explore Courses</Link>
    );
}

export default ExploreCourses;