"use client"
import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";

const Data = [
    {
        id: 0,
        value: 'item1',
        title: 'What does a Certification from ExC Academy mean?',
        content: 'A SAP Certification from ExC Academy is more than a badge of accomplishment; it is a passport to proficiency, a testament to expertise, and a gateway to unlocking boundless opportunities in the realm of enterprise solutions and innovation.',
    },
    {
        id: 1,
        value: 'item2',
        title: "What is the schedule and duration of the course?",
        content: 'The schedule and duration of ExC Academy courses vary based on the specific program. Courses may be offered on a flexible schedule, including part-time or full-time options. Detailed information about the schedule and duration can be found on the course page or by contacting the academy directly.',
    },
    {
        id: 2,
        value: 'item3',
        title: 'What is the class size for each session?',
        content: 'The class size at ExC Academy is tailored to ensure an optimal learning experience. While the exact number may vary for different courses, ExC Academy maintains small class sizes to facilitate personalized attention, interaction, and a collaborative learning environment.',
    },
    {
        id: 3,
        value: 'item4',
        title: 'What are the classes like?',
        content: 'Classes at ExC Academy are designed to be engaging, interactive, and focused on practical application. Instructors leverage a variety of teaching methods, including lectures, hands-on exercises, and group discussions. The goal is to create a dynamic learning atmosphere that fosters both individual growth and peer collaboration.',
    },
]

const FaqData = () => {
    const [open, setOpen] = useState<any>({
        value: 0,
        status: 'active'
    });
    const handleOpen = (value: any) => {
        setOpen({
            value: open.value === value ? 0 : value,
            status: open.value === value ? 'inactive' : 'active'
        })
    };
    return (
        <Accordion type="single" defaultValue="item1" collapsible className="w-full">
            {Data.map((i) => (
                <AccordionItem value={i.value} key={i.id}
                    onClick={(e: any) => handleOpen(i.id)}
                    className={`border-solid border-2 border-[#DBDBDB] px-5 mb-5 transition-bg ${open.value === i.id && open.status === 'active' ? "bg-[#F5F5F5]" : ""}`}>
                    <AccordionTrigger className="lg:text-lg md:text-base text-[1rem] text-start" color={open.status === 'active' && open.value === i.id ? '#515151' : '#9014DC'} >{i.title}</AccordionTrigger>
                    <AccordionContent className="font-normal text-[14px] md:text-sm text-[#666666] leading-relaxed">
                        {i.content}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </Accordion>
    );
}
export default FaqData;