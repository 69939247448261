import axios from 'axios';

const baseURL = process.env.NEXT_PUBLIC_API_DEV || 'http://localhost:3200';

const ApiFromClient = () => {
  const defaultOptions = {
    baseURL,
    headers: {
      "Content-type": "application/json",
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (request) => {
    // Modify the request here if needed
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // console.log(`error`, error);
      return error?.response?.data;
    },
  );

  return instance;
};

export default ApiFromClient();
