'use client';
import { useState } from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';


const StarRating = ({ ratings }: any) => {
    const [rating, setRating] = useState(4.5);
    const [hover, setHover] = useState(0);


    return (
        <div className='flex items-center'>
            <p className='pe-1 lg:text-sm md:text-sm text-[12px]'>(4.5)</p>
            <div className="star-rating">
                {/* {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        // <button
                        //     type="button"
                        //     key={index}
                        //     className={index <= (hover || rating) ? "offstar" : "off ratingbutton"}
                        //     onClick={() => setRating(index)}
                        //     onMouseEnter={() => setHover(index)}
                        //     onMouseLeave={() => setHover(rating)}
                        //     defaultValue={3}
                        // >
                        //     <span className="star text-xl" style={{width:'50%'}}>&#9733;</span>
                        // </button>
                    );
                })} */}
                <div className='flex flex-row'>
                    {[...Array(5)].map((_, index) => {
                        const starValue = index + 1;
                        if (starValue <= Math.floor(rating)) {
                            return (
                                <FaStar className="text-[#eeac01] mx-[2px]" key={index} />
                            );
                        } else if (starValue === Math.ceil(rating)) {
                            return (
                                <FaStarHalfAlt className="text-[#eeac01] mx-[2px]" key={index} />
                            );
                        } else {
                            return (
                                <FaRegStar className="text-gray-300" key={index} />
                            );
                        }
                    })}
                </div>
            </div>
            <p className='lg:px-5 md:px-5 px-2'>|</p>
            <p className='lg:text-base md:text-base text-[12px] font-normal'>{ratings} Enquiries</p>
        </div>
    );
}
export default StarRating;