"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { useEffect } from 'react';

const SapTraining = () => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);

    return (
        <section className='bg-[#F4EBF7] xl:pt-14 lg:pt-14 md:pt-14 pt-7 after:content-[""] after:bg-[url(https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/bg-tranprent.png)] after:absolute after:top-0 after:bottom-0 after:right-0 after:w-[250px] relative'>
            <div className='container relative z-10'>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='md:col-span-7 col-span-12'>
                        <h2 className='xl:text-[40px] md:text-[32px] text-[28px] font-normal mb-4'>Why should I choose <span className='text-[#C659D5] font-semibold'>ExC Academy</span> for my <span className='text-[#C659D5] font-semibold'>SAP training?</span></h2>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            Backed by Exalogic Consulting, an SAP Gold Partner.</p>
                        </div>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            SAP-certified trainers with extensive product and domain expertise. </p>
                        </div>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            Authorized recruitment ally for ExC Academy & Exalogic Consulting.</p>
                        </div>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            On Job training at our Bangalore office. </p>
                        </div>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            Only academy to offer SAP training coupled with professional development.</p>
                        </div>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            Smaller batch size for 1-on-1 focus.</p>
                        </div>
                        <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                            <p className="xl:text-[18px] md:text-[16px] text-[14px] font-normal pt-[5px] ml-3">
                            Placement assistance for qualified candidates.</p>
                        </div>
                    </div>
                    <div className='md:col-span-5 col-span-12'>
                        <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/teacher.webp' alt='teacher' width='420' height= '602' className='max-w-full m-auto'/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SapTraining; 