"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { useEffect } from 'react';

const ToolsForTeachers = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="bg-[#FAFAFA] xl:py-14 lg:py-14 md:py-14 py-7">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[20px] lg:gap-[50px] md:gap-[30px]">
                    <div className="order-last md:order-first">
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <h2 className="text-xl text-gray lg:text-[32px] md:text-2xl font-semibold lg:mb-7 mb-3 leading-tight">Tools For Teachers and Learners</h2>
                            <p className="font-normal text-gray text-[1rem] lg:text-lg md:text-base lg:mb-9 mb-4">
                                Class has a dynamic set of teaching tools built to be deployed and used during class.
                                Teachers can handout assignments in real-time for students to complete and submit.
                            </p>
                            <div className="flex">
                                <div className="me-5 w-[200px] flex flex-col items-center text-center border border-[#C7C7C7] px-4 py-3 lg:py-4">
                                    <Image height={100} width={100} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/sap_icon.webp"
                                        className="w-[56px] h-[32px] mb-3" alt="Tool for Teachers"
                                        blurDataURL="URL"
                                        placeholder="blur"
                                        fetchPriority="high"
                                        loading="eager"
                                        rel="preload"
                                    />
                                    <p className="text-[1rem] lg:text-sm md:text-[12px] font-normal">SAP Learning Hub</p>
                                </div>
                                <div className="w-[200px] flex flex-col items-center text-center border border-[#C7C7C7] px-4 py-3 lg:py-4">
                                    <Image height={100} width={100} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/online-learning.svg"
                                        className="w-[56px] h-[32px] mb-3" alt="Tool for Teachers"
                                        blurDataURL="URL"
                                        placeholder="blur"
                                        fetchPriority="high"
                                        loading="eager"
                                        rel="preload"
                                    />
                                    <p className="text-[1rem] lg:text-sm md:text-[12px] font-normal">Traning Platform for Learners</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-hidden rounded-tl-[30px] rounded-br-[40px] w-full h-auto m-auto order-first md:order-last relative flex justify-center md:justify-end">
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Image height={500} width={500} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/advantages-tools-for-teachers-and-learners.webp"
                                className="md:h-[300px] object-cover lg:h-[initial]" alt="Main Image"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ToolsForTeachers;