import Link from "../ui/link";
import { HiChevronRight } from "react-icons/hi";
const LearnMore = ({ className = '' }) => {
  const getClassName = () => {

  };

  const combinedClassName = `${getClassName()} ${className}`.trim();
  return (
    <Link href="/about-us" variant="secondary" className={combinedClassName}>Read More  <HiChevronRight className="text-[#0074FD]" /></Link>
  );
}

export default LearnMore;